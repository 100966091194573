import { useRoutes } from "react-router-dom";
import ThemeRoutes from "./routes/Router";

function App() {
  const routes = useRoutes(ThemeRoutes)
  return (
    <div>
      {routes}
    </div>
  );
}

export default App;
