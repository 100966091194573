import Form from "./Form";
import shape_90 from "../assets/Images/shape/shape_90.svg"
import shape_91 from "../assets/Images/shape/shape_91.svg"
import icon_60 from "../assets/Images/icon/icon_60.svg"
import whatsapp from "../assets/Images/icon/whatsapp.png"

const Contact = () => {
    const contentData = {
        imgSrc: icon_60,
        title: `Have a Project? Let’s Talk & Grow your Business`,
        subtitle:
            "Discuss with our expert advisors",
        urgent: "Contact us on WhatsApp",
        phone: "+121 3594 4154",
        link: "https://wa.me/12135944154"
    };

    return (
        <div className="row align-items-center">
            <div className="col-lg-5" data-aos="fade-right">
                <img src={contentData.imgSrc} alt="img" className="lazy-img" />
                <div className="title-style-one mt-35 mb-30">
                    <h2 className="main-title fw-normal tx-dark m0">
                        {contentData.title}
                    </h2>
                </div>
                <p className="text-lg tx-dark mb-55 lg-mb-30">{contentData.subtitle}</p>

                <div className="fw-500 fs-20 ur-text mb-5">{contentData.urgent}</div>
                <div className="call-btn">
                    <span className="fs-20 tx-dark d-flex align-items-center me-2"><span className="me-2"><img src={whatsapp} height={30} alt="whatsapp" /></span>Call us <a href={contentData.link} target="_blank" className="ms-2 fs-20 tx-dark tran3s">
                        {contentData.phone}
                    </a></span>

                </div>
            </div>

            {/* End .col-lg-5 */}

            <div className="col-xl-5 col-lg-6 ms-auto" data-aos="fade-left">
                <div className="form-bg-wrapper position-relative rounded bg-white pe-4 ps-4 pe-lg-5 ps-lg-5 pt-60 pb-50 md-mt-60">
                    <div className="form-style-two">
                        <Form />
                    </div>
                    {/* /.form-style-two */}

                    <div className="shapes shape-text fw-500 fs-20 tx-dark text-center">
                        Fill the <br />
                        form
                    </div>
                    <img
                        src={shape_90}
                        alt="img"
                        className="lazy-img shapes shape-one"
                    />
                    <img
                        src={shape_91}
                        alt="img"
                        className="lazy-img shapes shape-two"
                    />
                </div>
                {/* /.form-bg-wrapper */}
            </div>
        </div>
    );
};

export default Contact;
