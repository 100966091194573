import shape_172 from '../assets/Images/shape/shape_172.svg'
import shape_175 from '../assets/Images/shape/shape_175.svg'

const PortfolioTopTitle = () => {
    return (
        <div className="fancy-feature-fiftyOne position-relative xs-mt-100 mt-200">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7" data-aos="fade-right">
                        <div className="title-style-five mb-65 lg-mb-40 xs-mb-10">
                            <div className="sc-title-two fst-italic position-relative">
                                Our Recent Work
                            </div>
                            <h2 className="main-title fw-500 tx-dark">
                                Check out our Products.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* /.container */}
            <img
                src={shape_172}
                alt="shap"
                className="lazy-img shapes shape-two"
            />
            <img
                src={shape_175}
                alt="shap"
                className="lazy-img shapes shape-three"
            />
        </div>
    );
};

export default PortfolioTopTitle;
