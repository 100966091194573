import { Link } from "react-router-dom";
import SignupForm from "../components/SignUpForm";
import b_logo from '../assets/Images/logo/b_logo.svg'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ils_11 from "../assets/Images/ils_11.png"
import ils_12 from "../assets/Images/ils_12.png"

const SignUp = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <header className="theme-main-menu sticky-menu theme-menu-eight">
                <div className="inner-content position-relative">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="logo order-lg-0">
                            <Link to="/" className="d-block">
                                <img src={b_logo} alt="logo" width={200} />
                            </Link>
                        </div>
                        <Link to="/" className="go-back-btn fw-500 tran3s">
                            Go to home
                        </Link>
                    </div>
                </div>
            </header>
            <div className="user-data-section d-flex align-items-center justify-content-center flex-column position-relative">
                <div className="form-wrapper position-relative m-auto">
                    <div className="text-center">
                        <h2 className="tx-dark mb-30 lg-mb-10">Registration</h2>
                        {/* <p className="fs-20 tx-dark">
              Have an account? <Link href="/login" className="text-theme text-decoration-underline">Login Here</Link>
            </p> */}
                    </div>
                    <SignupForm />
                </div>
                {/* End form-wrapper */}

                <p className="mt-auto pt-50">Copyright &copy; {`${currentYear}`} Beckmarket inc.</p>
                <img
                    src={ils_11}
                    alt="illustration"
                    className="lazy-img illustration-one wow fadeInRight"
                />
                <img
                    src={ils_12}
                    alt="illustration"
                    className="lazy-img illustration-two wow fadeInLeft"
                />
            </div>
            <ToastContainer />
            {/* /.fancy-feature-fiftyOne */}
        </>
    );
};

export default SignUp;
