import React from 'react'
import { ScriptContextProvider } from './store'
import { CategoryContextProvider } from './CategoryContext'
import { AttributeContextProvider } from './AttributeContext'

const ThemeContext = (props) => {
  return (
    <AttributeContextProvider>
      <ScriptContextProvider>
        <CategoryContextProvider>
          {props.children}
        </CategoryContextProvider>
      </ScriptContextProvider>
    </AttributeContextProvider>
  )
}

export default ThemeContext