import { useState } from "react";
import { url, imgUrl } from "../Data/Url";
import ReactPlayer from 'react-player';
import { Gallery, Item } from "react-photoswipe-gallery";
import icon_140 from "../assets/Images/icon/icon_140.svg";
const ProjectSlide = ({ slide }) => {
    const [showVideo, setShowVideo] = useState(false);
    const options = {
        wheelToZoom: true,
        secondaryZoomLevel: 2,
        maxZoomLevel: 2,

    }
    const videoHandler = () => {
        setShowVideo(true);
    };
    const images = [slide?.thumbnail ?? ""];
    return (
        <>
            {slide.videoId && slide.videoId.length !== 0 && slide.videoId !== "undefined" ? <div>
                <div className="fancy-feature-fiftyTwo xs-mt-10 lg-mt-100">
                    <div className="container">
                        {!showVideo && <div className="video-banner-main d-flex align-items-center justify-content-center" style={{ backgroundImage: `url(${imgUrl}/${slide.thumbnail})` }}>
                            <button
                                className="fancybox video-icon tran3s rounded-circle d-flex align-items-center justify-content-center"
                                onClick={videoHandler}
                            >
                                <img
                                    src={icon_140}
                                    alt="icon"
                                    className="lazy-img"
                                />
                            </button>
                        </div>}
                        {showVideo && (
                            <div className="video-player">
                                <ReactPlayer
                                    url={`https://www.youtube.com/watch?v=${slide.videoId}`}
                                    controls
                                    playing={true}
                                    width="100%"
                                    className="responsive-player"
                                />
                            </div>
                        )}
                        {/* /.video-banner */}
                    </div>
                </div>
            </div> :
                <Gallery options={options}>
                    {images && images.length > 0 && images.map((image, index) => (
                        <div
                            className={`col-12`}
                            key={index}
                        >
                            <div className="img-meta mt-30 mt-md-0 wow fadeInLeft">
                                <Item
                                    original={`${imgUrl}/${image}`}
                                    thumbnail={"img"}
                                    width={index === 4 ? 1290 : 880}
                                    height={700}
                                >
                                    {({ ref, open }) => (
                                        <span
                                            role="button"
                                            className="fancybox d-block"
                                            title="Click for large view"
                                            ref={ref}
                                            onClick={open}
                                        >
                                            <img
                                                width={460}
                                                layout="responsive"
                                                src={`${imgUrl}/${image}`}
                                                alt={"img"}
                                                className="lazy-img banner-img w-100 object-fit-cover"
                                            />
                                        </span>
                                    )}
                                </Item>
                            </div>
                        </div>
                    ))}
                </Gallery>
                // <div
                //   id="gallery-carousel"
                //   className="carousel slide me-xxl-5 md-mb-40"
                //   data-bs-ride="carousel"
                // >
                //   <div className="carousel-inner">
                //     {images.map((image, index) => (
                //       <div
                //         className={`carousel-item ${index === 0 ? "active" : ""}`}
                //         key={index}
                //       >
                //         <img
                //           width={824}
                //           height={654}
                //           layout="responsive"
                //           src={`${url}/${image}`}
                //           className="d-block w-100 detail-slide-img"
                //           alt="media"
                //         />
                //       </div>
                //     ))}
                //   </div>
                //   {/* End carousel-inner */}

                //   <button
                //     className="carousel-control-prev d-none"
                //     type="button"
                //     data-bs-target="#gallery-carousel"
                //     data-bs-slide="prev"
                //   >
                //     <i className="bi bi-chevron-left"></i>
                //     <span className="visually-hidden">Previous</span>
                //   </button>
                //   {/* End prev */}

                //   <button
                //     className="carousel-control-next d-none"
                //     type="button"
                //     data-bs-target="#gallery-carousel"
                //     data-bs-slide="next"
                //   >
                //     <i className="bi bi-chevron-right"></i>
                //     <span className="visually-hidden">Next</span>
                //   </button>
                //   {/* End next */}
                // </div>
            }
        </>
    );
};

export default ProjectSlide;
