import { useState } from "react";
import ModalVideo from "react-modal-video";
import { url } from "../Data/Url";
import thumbnail from "../assets/Images/videoThumbnail.jpg"
import icon_140 from "../assets/Images/icon/icon_140.svg"

const VideoBlock = ({ imgthumbnail, videoId, about }) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId={videoId ? videoId : "FKas7XMP0jc"}
                onClose={() => setOpen(false)}
            />
            <div className="fancy-feature-fiftyTwo lg-mt-100">
                <div className="container">
                    <div className="video-banner d-flex align-items-center justify-content-center shadow" style={{ backgroundImage: `url(${about ? thumbnail : url + "/" + imgthumbnail})` }}>
                        <button
                            className="fancybox video-icon tran3s rounded-circle d-flex align-items-center justify-content-center"
                            onClick={() => setOpen(true)}
                        >
                            <img
                                src={icon_140}
                                alt="icon"
                                className="lazy-img"
                            />
                        </button>
                    </div>
                    {/* /.video-banner */}
                </div>
            </div>
        </>
    );
};

export default VideoBlock;
