import React from "react";
import { Link } from "react-router-dom";
import shape_132 from "../assets/Images/shape/shape_132.svg"

const CallToAction = () => {
    return (
        <div className="fancy-short-banner-twelve position-relative zn2 pt-50 lg-pt-50">
            <div className="container">
                <div className="border-bottom pb-140 lg-pb-80">
                    <div className="row">
                        <div className="col-xxl-9 col-xl-10 m-auto text-center">
                            <div
                                className="title-style-ten"
                            >
                                <h2 className="main-title fw-500 tx-dark">
                                    Have a Project? <br />
                                    <span className="position-relative">
                                        Let’s Talk{" "}
                                        <img src={shape_132} alt="shape" />
                                    </span>
                                    &amp; Grow your Business
                                </h2>
                            </div>
                            {/* /.title-style-ten */}
                            <p
                                className="text-lg mt-45 mb-55 lg-mb-30 lg-mt-40"
                            >
                                We’re ready to help you. Our expert is here, just send a message.
                            </p>
                            <Link
                                to="/contact"
                                className="btn-twenty fw-500 tran3s"
                            >
                                Get In Touch
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shapes shape-one" />
        </div>
    );
};

export default CallToAction;
