import icon_147 from '../assets/Images/icon/icon_147.svg'
import icon_149 from '../assets/Images/icon/icon_149.svg'
import icon_148 from '../assets/Images/icon/icon_148.svg'
import whatsapp from '../assets/Images/icon/whatsapp.png'

const BlockContact = () => {
    const addressBlocks = [
        {
            icon: icon_147,
            title: "Our Address",
            content: "103, First Floor, Saeed Colony no.1 near Boulevard mall, W Canal Rd, Faisalabad, 38000",
            delay: "100",
        },
        {
            icon: icon_149,
            title: "Contact Info",
            content: "Open a chat or give us call on WhatsApp",
            link: "https://wa.me/12135944154",
            num: "1 (213) 594-4154",
            delay: "200",
        },
        {
            icon: icon_148,
            title: "Email",
            content: "For more information",
            link: "sales@beckmarket.co",
            delay: "300",
            href: "mailto:contactus@tecbeck.com"
        },
    ];

    return (
        <>
            {addressBlocks.map((block, index) => (
                <div
                    className="col-md-4"
                    key={index}
                    data-aos="fade-up"
                    data-aos-delay={block.delay}
                >
                    <div className="address-block-two text-center mb-40">
                        <div className="icon rounded-circle d-flex align-items-center justify-content-center m-auto">
                            <img width={50} height={50} src={block.icon} alt="icon" />
                        </div>
                        <h5 className="title">{block.title}</h5>
                        <p>
                            {block.content} <br />
                            {block.link && (
                                <div className="d-flex align-item-center justify-content-center my-3">
                                    {index === 1 && <img src={whatsapp} className="me-2" height={'35'} width={'35px'} alt="whatsapp" />}
                                    <a
                                        href={block.href ? block.href : block.link}
                                        target="_blank"
                                        className={
                                            block.link.includes("tel:") ? "call" : "webaddress"
                                        }
                                    >

                                        {block.num ? block.num : block.link.replace("tel:", "")}
                                    </a>
                                </div>
                            )}
                        </p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default BlockContact;
