module.exports = [
    {
        id: 1,
        title: "Precision",
        rating: 5,
        text: "Thank you for your work and accuracy, I look forward to working with you again",
        author: "Brown Roye",
        location: "United States",
        image: "/images/media/img_54.jpeg",
    },
    {
        id: 2,
        title: "Efficiency",
        rating: 5,
        text: "I loved working with them! My website was completed within a week and this is with several plugins that I needed for my business and graphics.",
        author: "May Jhon",
        location: "United States",
        image: "/images/media/img_55.jpg",
    },
    {
        id: 3,
        title: "Flexibility",
        rating: 5,
        text: "Very understanding of the changes I needed and very flexible and patient with me. I’ll recommend them 100%.",
        author: "Park Jimin",
        location: "Korea",
        image: "/images/media/img_56.jpeg",
    },
    {
        id: 4,
        title: "Recommendation Worthy",
        rating: 5,
        text: "It was great to work with them. I can definitely recommend them to anyone who wants to have an amazing UI/UX application.",
        author: "Phill Olli",
        location: "United Kingdom",
        image: "/images/media/img_55.jpg",
    },
];
