import { createContext, useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { url } from "../Data/Url";

const AttributeContext = createContext({
    data: [],
});

export const AttributeContextProvider = (props) => {
    const [categories, setCategories] = useState([]);

    const GetCategories = async () => {
        try {
            const response = await fetch(`${url}/scriptinfo/`, {
                method: "GET",
            });
            const data = await response.json();
            if (data.status === false) {
                toast.warning('No Category available right now.');
            } else {
                setCategories(data);
            }
        } catch (error) {
            toast.warning('Your network might be slow. Please reload your page.');
        }
    };

    useEffect(() => {
        GetCategories();
    }, []);

    const categoryData = useMemo(() => {
        return {
            data: categories,
        };
    }, [categories]);
    return (
        <AttributeContext.Provider value={categoryData}>
            {props.children}
        </AttributeContext.Provider>
    );
};

export default AttributeContext;
