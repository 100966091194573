import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/Images/logo/b_logo.svg"

const MainMenu = () => {
    const location = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const closeNav = () => {
        setIsNavOpen(false);
        document.getElementById("navbarNav").classList.remove("show");
    };
    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    return (
        <nav className="navbar navbar-expand-lg order-lg-2">
            <button
                className={`navbar-toggler d-block d-lg-none ${isNavOpen ? "collapsed" : ""}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={isNavOpen ? "true" : "false"}
                onClick={toggleNav}
                aria-label="Toggle navigation"
            >
                <span />
            </button>
            {/* End mobile collapse menu */}

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="d-block d-lg-none">
                        <div className="logo">
                            <Link to="/" onClick={closeNav} className="d-block">
                                <img src={logo} alt="" width={200} />
                            </Link>
                        </div>
                    </li>
                    {/* End li */}

                    <li className={
                        location.pathname === "/" ? "active nav-item" :
                            "nav-item"
                    }>
                        <Link to="/" onClick={closeNav} className='nav-link'>
                            Home
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/about-us" ? "active nav-item" :
                                "nav-item"
                        }
                    >
                        <Link to="/about-us" onClick={closeNav} role="button" className="nav-link">
                            About Us
                        </Link>
                    </li>
                    <li className={
                        location.pathname === "/products" ? "active nav-item" :
                            "nav-item"
                    }>
                        <Link to="/products" onClick={closeNav} className='nav-link'>
                            Products
                        </Link>
                    </li>
                    <li
                        className={
                            location.pathname === "/contact" ? "active nav-item" :
                                "nav-item"
                        }
                    >
                        <Link to="/contact" onClick={closeNav} role="button" className="nav-link">
                            Contact
                        </Link>
                    </li>

                    {/* End li (contact) */}
                </ul>
                {/* End ul */}

                {/* Mobile Content */}
                <div className="mobile-content d-block d-lg-none">
                    <div className="d-flex flex-column align-items-center justify-content-center mt-70">
                        <Link to="/contact" onClick={closeNav} className="btn-twentyOne fw-500 tran3s">
                            Contact us
                        </Link>
                    </div>
                </div>
                {/* /.mobile-content */}
            </div>
        </nav>
    );
};

export default MainMenu;
