import BlockContact from "../components/BlockContent";
import ContactForm from "../components/ContactForm";
import Map from "../components/Map";
import Partners from "../components/Partners";
import shape_38 from "../assets/Images/shape/shape_38.svg"
import shape_39 from "../assets/Images/shape/shape_39.svg"
import shape_172 from "../assets/Images/shape/shape_172.svg"
import { Helmet } from "react-helmet";

const ContactUs = () => {

    return (
        <>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            {/* 
        =============================================
        Feature Section Fifty One
        ============================================== 
        */}
            <div className="fancy-feature-fiftyOne position-relative mt-250 lg-mt-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 text-center m-auto wow fadeInUp">
                            <div className="title-style-five mb-65 lg-mb-40">
                                <div className="sc-title-two fst-italic position-relative d-inline-block">
                                    Contact Info
                                </div>
                                <h2 className="main-title fw-500 tx-dark">Get in Touch.</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.container */}
                <img
                    src={shape_172}
                    alt="shape"
                    className="lazy-img shapes shape-two"
                />
            </div>

            {/* 
        =============================================
        Contact Section One
        ============================================== 
        */}
            <div className="contact-section-one mt-60 lg-mt-30">
                <div className="container">
                    <div className="row">
                        <BlockContact />
                    </div>
                </div>
                {/* End .container */}

                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-9 m-auto">
                            <h2 className="tx-dark text-center mt-100 mb-80 lg-mt-40 lg-mb-40 wow fadeInUp">
                                Any question? Send us message.
                            </h2>
                        </div>
                        <div className="col-xl-11 m-auto">
                            <ContactForm />
                            {/* /.form-style-one */}
                        </div>
                    </div>
                </div>
                {/* End .container */}
                <Map />
            </div>

            {/* 
        =============================================
        Contact Section One
        ============================================== 
        */}
            <div className="partner-section-one position-relative my-5-theme lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <div className="row align-items-center">
                                <Partners />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.container */}

                <img
                    src={shape_38}
                    alt="shape"
                    className="lazy-img shapes shape-one"
                />
                <img
                    src={shape_39}
                    alt="shape"
                    className="lazy-img shapes shape-two"
                />
            </div>
        </>
    );
};

export default ContactUs;
