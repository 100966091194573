import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import { url, imgUrl } from "../Data/Url";

const PortfolioGallery = ({ imageUrlArray, banner }) => {
    const options = {
        wheelToZoom: true,
        secondaryZoomLevel: 2,
        maxZoomLevel: 2,

    }
    return (
        <>
            <Gallery options={options}>
                {imageUrlArray && imageUrlArray.length > 0 && imageUrlArray.map((image, index) => (
                    <div
                        className={`col-12 ${index === 4 ? "col-sm-8" : "col-sm-4"}`}
                        key={index}
                    >
                        <div className="img-meta mt-30 wow fadeInLeft">
                            <Item
                                original={`${imgUrl}/${image}`}
                                thumbnail={"img"}
                                width={index === 4 ? 1290 : 880}
                                height={700}
                            >
                                {({ ref, open }) => (
                                    <span
                                        role="button"
                                        className="fancybox d-block"
                                        title="Click for large view"
                                        ref={ref}
                                        onClick={open}
                                    >
                                        <img
                                            width={460}
                                            layout="responsive"
                                            src={`${imgUrl}/${image}`}
                                            alt={"img"}
                                            className="lazy-img w-100 object-fit-cover img-gallery"
                                        />
                                    </span>
                                )}
                            </Item>
                        </div>
                    </div>
                ))}
                <div
                    className={"col-12 col-sm-8"}
                >
                    <div className="img-meta mt-30 wow fadeInLeft">
                        <Item
                            original={`${imgUrl}/${banner}`}
                            thumbnail={"img"}
                            width={1390}
                            height={600}
                        >
                            {({ ref, open }) => (
                                <span
                                    role="button"
                                    className="fancybox d-block"
                                    title="Click for large view"
                                    ref={ref}
                                    onClick={open}
                                >
                                    <img
                                        width={460}
                                        layout="responsive"
                                        src={`${imgUrl}/${banner}`}
                                        alt={"img"}
                                        className="lazy-img w-100 object-fit-cover img-gallery"
                                    />
                                </span>
                            )}
                        </Item>
                    </div>
                </div>
            </Gallery>
        </>
    );
};

export default PortfolioGallery;
