import PortfolioTopTitle from "../components/PortfolioTopTitle";
import CallToAction from "../components/CallToAction";
import Products from "../components/CategoryProducts";
import Partners from "../components/Partners";
import { useParams } from "react-router-dom";
import shape_38 from '../assets/Images/shape/shape_38.svg'
import shape_39 from '../assets/Images/shape/shape_39.svg'
import { Helmet } from "react-helmet";

const Category = () => {
    const router = useParams();
    const id = router.id;
    return (
        <>
            <Helmet>
                <title>Products</title>
            </Helmet>
            <PortfolioTopTitle />

            {/* <!-- 
        =============================================
        Portfolio Gallery Three
        ============================================== 
        --> */}
            {/* <PortfolioGallery3 /> */}
            <div className="xs-mt-10 mtt-5">
                <Products id={id} />
            </div>

            {/*
			=====================================================
				Fancy Short Banner Twelve
			=====================================================
			*/}
            <CallToAction />

            {/* 
        =============================================
        Contact Section One
        ============================================== 
        */}
            <div className="partner-section-one position-relative my-5-theme lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <div className="row align-items-center">
                                <Partners />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.container */}

                <img
                    src={shape_38}
                    alt="shape"
                    className="lazy-img shapes shape-one"
                />
                <img
                    src={shape_39}
                    alt="shape"
                    className="lazy-img shapes shape-two"
                />
            </div>
        </>
    );
};

export default Category;
