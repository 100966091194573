import React, { useState } from 'react'
import Hero from "../components/Hero";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";
import Partners from "../components/Partners";
import shape_83 from "../assets/Images/shape/shape_83.svg";
import shape_84 from "../assets/Images/shape/shape_84.svg";
import shape_85 from "../assets/Images/shape/shape_85.svg";
import shape_92 from "../assets/Images/shape/shape_92.svg";
import shape_93 from "../assets/Images/shape/shape_93.svg";
import shape_38 from "../assets/Images/shape/shape_38.svg";
import shape_39 from "../assets/Images/shape/shape_39.svg";
import { Helmet } from 'react-helmet';

const Home = () => {
    const [searchValue, setSearchValue] = useState();
    const getSearchValue = (value) => {
        setSearchValue(value)
    }
    return (
        <div>
            <Helmet>
                <title>Beckmarket</title>
            </Helmet>
            <div className="hero-banner-six position-relative pt-180 md-pt-150">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 m-auto text-center" data-aos="fade-up">
                            <Hero searchValue={getSearchValue} />
                            {/* /.subscribe-form */}
                        </div>
                    </div>
                    {/* End .row */}
                </div>
                {/* /.container */}
                <div className="mtt-5">
                    <Portfolio searchValue={searchValue} />
                </div>
                <div className="shapes shape-one rounded-circle" />
                <img
                    src={shape_83}
                    alt="shape"
                    className="lazy-img shapes shape-two"
                />
                <div className="shapes shape-three" />
                <img
                    src={shape_84}
                    alt="shape"
                    className="lazy-img shapes shape-four"
                />
                <img
                    src={shape_85}
                    alt="shape"
                    className="lazy-img shapes shape-five"
                />
                <div className="shapes shape-six rounded-circle" />
                <div className="shapes shape-seven rounded-circle" />
                <img
                    src={shape_84}
                    alt="shape"
                    className="lazy-img shapes shape-eight"
                />
                <img
                    src={shape_83}
                    alt="shape"
                    className="lazy-img shapes shape-nine"
                />
            </div>
            <div className="fancy-feature-twentyTwo position-relative pt-170 pb-170 lg-pt-80 lg-pb-100">
                <div className="fancy-short-banner-eight">
                    <div className="container">
                        <Contact />
                    </div>
                    {/* /.container */}
                </div>
                {/* /.fancy-short-banner-eight */}
                {/* /.feedback-section-five */}

                <div className="shapes shape-three rounded-circle" />
                <div className="shapes shape-four rounded-circle" />
                <div className="shapes shape-five rounded-circle" />
                <div className="shapes shape-six rounded-circle" />
                <img
                    src={shape_92}
                    alt="shape"
                    className="lazy-img shapes shape-seven"
                />
                <img
                    src={shape_93}
                    alt="shape"
                    className="lazy-img shapes shape-eight"
                />
            </div>
            <div className="partner-section-one position-relative my-5-theme lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <div className="row align-items-center">
                                <Partners />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.container */}

                <img
                    src={shape_38}
                    alt="shape"
                    className="lazy-img shapes shape-one"
                />
                <img
                    src={shape_39}
                    alt="shape"
                    className="lazy-img shapes shape-two"
                />
            </div>
        </div>
    )
}

export default Home