import shape_136 from "../assets/Images/shape/shape_136.svg"
import shape_137 from "../assets/Images/shape/shape_137.svg"
import shape_138 from "../assets/Images/shape/shape_138.svg"
const Block = () => {
    return (
        <>
            <div className="row align-items-end" data-aos="fade-up">
                <div className="col-md-5 col-sm-4">
                    <img
                        src={shape_136}
                        alt="shape"
                        className="lazy-img d-none d-sm-inline-block"
                    />
                </div>
                <div className="col-md-7 col-sm-8 wow fadeInDown">
                    <div className="block-wrapper block-one">
                        <h3 style={{ color: "#FFAE10" }}>Unmatched Selection</h3>
                        <p>Provides a wide selection of carefully developed mobile apps, UI/UX designs, and websites for web applications.</p>
                    </div>
                    {/* /.block-wrapper */}
                </div>
            </div>
            {/* End .row */}

            <div className="row gx-xxl-5" data-aos="fade-down">
                <div className="col-sm-7 wow fadeInDown">
                    <div className="block-wrapper block-two position-relative mt-50 sm-mt-30">
                        <h3 style={{ color: "#9650EF" }}>
                            Quality Control
                        </h3>
                        <p>
                            We make sure every product satisfies the highest standards of functionality & innovation.
                        </p>
                        <img
                            src={shape_138}
                            alt="sahpe"
                            className="lazy-img shapes shape-one"
                        />
                    </div>
                    {/* /.block-wrapper */}
                </div>
                {/* End .col */}

                <div className="col-sm-5 wow fadeInUp">
                    <div className="block-wrapper block-three mt-50 sm-mt-30">
                        <h3 style={{ color: "#00BDE6" }}>
                            <span>Easy Search</span>
                        </h3>
                        <p>Easily identify your perfect website and specialist solutions.</p>
                    </div>
                    {/* /.block-wrapper */}
                    <img
                        src={shape_137}
                        alt="shape"
                        className="lazy-img mt-30 ms-auto me-auto d-none d-sm-inline-block"
                    />
                </div>
            </div>
        </>
    );
};

export default Block;
