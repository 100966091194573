import { createContext, useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { url } from "../Data/Url";

const ScriptContext = createContext({
    data: [],
});

export const ScriptContextProvider = (props) => {
    const [scriptList, setScriptList] = useState([]);

    const GetScripts = async () => {
        try {
            const response = await fetch(`${url}/script/`, {
                method: "GET",
            });
            const data = await response.json();
            if (data.status === false) {
                toast.warning('No script available right now.');
            } else {
                setScriptList(data);
            }
        } catch (error) {
            toast.warning('Your network might be slow. Please reload your page.');
        }
    };

    useEffect(() => {
        GetScripts();
    }, []);

    const scriptData = useMemo(() => {
        return {
            data: scriptList,
        };
    }, [scriptList]);

    return (
        <ScriptContext.Provider value={scriptData}>
            {props.children}
        </ScriptContext.Provider>
    );
};

export default ScriptContext;
