import React from 'react'
import Counter from "../components/Counter"
import VideoBlock from "../components/VideoBlock"
import Block from "../components/Block"
import Testimonial from "../components/Testimonial"
import Faq from "../components/Faq"
import Partners from "../components/Partners"
import { Link } from 'react-router-dom'
import shape_171 from "../assets/Images/shape/shape_171.svg"
import shape_172 from "../assets/Images/shape/shape_172.svg"
import shape_38 from "../assets/Images/shape/shape_38.svg"
import shape_39 from "../assets/Images/shape/shape_39.svg"
import { Helmet } from 'react-helmet'

const About = () => {
    const features = [
        { text: "Amazing communication." },
        { text: "Best trending designing & development experience." },
        { text: "Email or contact us on WhatsApp." },
    ];
    return (
        <div>
            <Helmet>
                <title>About Us</title>
            </Helmet>
            <div className="fancy-feature-fiftyOne position-relative mt-100 mb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7" data-aos="fade-right">
                            <div className="title-style-five mb-65 md-mb-40">
                                <div className="sc-title-two fst-italic position-relative">
                                    More About us
                                </div>
                                <h2 className="main-title fw-500 tx-dark">
                                    Beckmarket – Experience Digital Excellence!
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 ms-auto">
                            <div className="ps-xxl-5" data-aos="fade-left">
                                <h6 className="mb-30">Our Goal</h6>
                                <p className="text-lg tx-dark">
                                    Beckmarket aims on helping customers achieve their goals by connecting them with outstanding application that correspond to their specific requirements. As an innovative platform, we are experts at identifying and providing a wide variety of application, including mobile applications, web applications, and UI/UX designs created by highly skilled experts.
                                </p>
                                <div className="row">
                                    <Counter />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.container */}

                <img
                    src={shape_171}
                    alt="shape"
                    className="lazy-img shapes shape-one"
                />
                <img
                    src={shape_172}
                    alt="shape"
                    className="lazy-img shapes shape-two"
                />
            </div>
            {/* /.fancy-feature-fiftyOne */}

            {/* <!-- 
			=============================================
				Feature Section Fifty Two
			============================================== 
			--> */}
            <VideoBlock about={true} />

            {/* <!-- /.fancy-feature-fiftyTwo --> */}

            {/* 
			=============================================
				Feature Section Thirty Seven
			============================================== 
			*/}
            <div className="fancy-feature-thirtySeven mt-50 lg-mt-50">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div
                                className="block-style-four pe-xxl-5 me-xxl-4 md-mb-70"
                                data-aos="fade-right"
                            >
                                <div className="title-style-six">
                                    <div className="sc-title-two text-uppercase">WHY US</div>
                                    <h2 className="main-title fw-500 tx-dark">
                                        Why Choose Beckmarket?
                                    </h2>
                                </div>
                                {/* /.title-style-ten */}
                                <p className="fs-20 pt-20 pb-30 lg-pt-10 lg-pb-20">
                                    Use Beckmarket to transform your company into a sales machine right now. When something goes wrong, or have any inquiries
                                </p>
                                <ul className="style-none list-item">
                                    {features.map((feature, index) => (
                                        <li key={index}>{feature.text}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {/* End .col */}

                        <div className="col-xxl-5 col-lg-6 ms-auto">
                            <Block />
                        </div>
                        {/* End .col */}
                    </div>
                </div>
                {/* /.container */}
            </div>
            {/* /.fancy-feature-thirtySeven */}

            {/*
			=====================================================
				Team Section Two
			=====================================================
			*/}
            <div className="team-section-two position-relative pt-50 lg-pt-120">
                <div className="container">
                    <div className="wrapper position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-5" data-aos="fade-right">
                                <div className="title-style-six text-center text-lg-start pb-40 lg-pb-20 md-pb-10">
                                    {/* <h2 className="main-title fw-500 tx-dark">Our Team</h2> */}
                                </div>
                                {/* /.title-style-six */}
                            </div>
                        </div>
                        {/* /.row */}
                        <div className="row">
                            {/* <Team3 /> */}
                        </div>
                        {/* /.row */}

                        {/* <p
              className="cr-text text-center text-lg tx-dark mt-75 lg-mt-50"
              data-aos="fade-up"
            >
              Our nearly
              <span className="text-decoration-underline fw-500">8,000+</span>
              committed staff members are ready to help.
            </p> */}
                        {/* <div className="text-center md-mt-20">
              <Link
                href="/pages-menu/team-v1"
                className="btn-twentyTwo fw-500 tran3s"
                data-aos="fade-left"
              >
                View full team
              </Link>
            </div> */}
                    </div>
                    {/* /.wrapper */}
                </div>
                {/* /.container */}
                <img
                    src={shape_172}
                    alt="shape"
                    className="lazy-img shapes shape-one d-none d-xl-inline-block"
                />
            </div>
            {/* /.team-section-two */}

            {/*
			=====================================================
				Feedback Section Eleven
			=====================================================
			*/}
            <div
                className="feedback-section-eleven position-relative mt-50 pt-100 pb-70 lg-pt-70 lg-pb-50"
                data-aos="fade-up"
            >
                <div className="container">
                    <div className="title-style-one text-center mb-50 lg-mb-20">
                        <h2 className="main-title fw-500 tx-dark m0">Client Feedback</h2>
                    </div>
                </div>
                {/* End .container */}
                <div className="inner-content">
                    <div className="slider-wrapper">
                        <div className="feedback_slider_seven">
                            <Testimonial />
                        </div>
                        {/* /.feedback_slider_seven */}
                    </div>
                    {/* /.slider-wrapper */}
                </div>
                {/* /.inner-content */}
            </div>
            {/* /.feedback-section-eleven */}

            {/* 
			=============================================
				Feature Section Thirty Eight
			============================================== 
			*/}
            <div className="fancy-feature-thirtyEight mt-50 lg-mt-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="block-style-seven" data-aos="fade-right">
                                <div className="title-style-six">
                                    <div className="sc-title-two text-uppercase">SOLUTION</div>
                                    <h2 className="fw-500 tx-dark">
                                        Technology business solutions.
                                    </h2>
                                </div>
                                {/* /.title-style-ten */}
                                <p className="fs-20 pt-10 pb-30 lg-pb-20">
                                    Do you still have questions concerning our solution? To get help, just drop us an email.
                                </p>
                                {/* <div className="btn-eighteen position-relative d-inline-block tx-dark">
                  <Link href="/page-menu/about-us-v1" className="fw-500 tran3s">
                    More About solution
                    <i className="fa-solid fa-angle-right" />
                  </Link>
                </div> */}
                            </div>
                            {/* /.block-style-seven */}
                        </div>
                        {/* End .col-lg-5 */}
                        <div className="col-lg-6 ms-auto" data-aos="fade-left">
                            <Faq />
                        </div>
                    </div>
                </div>
                {/* /.container */}
            </div>
            {/* /.partner-section-six */}

            {/*
			=====================================================
				Fancy Short Banner Sixteen
			=====================================================
			*/}
            <div
                className="fancy-short-banner-sixteen mt-130 lg-mt-80"
                data-aos="fade-up"
            >
                <div className="container">
                    <div className="bg-wrapper pt-65 pb-65 lg-pt-40 lg-pb-40">
                        <div className="row">
                            <div className="col-xl-10 col-md-11 m-auto">
                                <div className="row align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text-wrapper text-center text-lg-start md-pb-30">
                                            <div className="sc-title fs-18 pb-10">
                                                Have a Project?
                                            </div>
                                            <h2 className="main-title fw-500 text-white m0">
                                                Don’t hesitate to send us message.
                                            </h2>
                                        </div>
                                    </div>
                                    {/* End .col-6 */}

                                    <div className="col-lg-5 ms-auto text-center text-lg-end">
                                        <Link
                                            to="/contact"
                                            className="btn-twentyOne fw-500 tran3s"
                                        >
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.bg-wrapper */}
                </div>
            </div>
            {/* /.fancy-short-banner-sixteen */}

            {/* 
        =============================================
        Contact Section One
        ============================================== 
        */}
            <div className="partner-section-one position-relative my-5-theme lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <div className="row align-items-center">
                                <Partners />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.container */}

                <img
                    src={shape_38}
                    alt="shape"
                    className="lazy-img shapes shape-one"
                />
                <img
                    src={shape_39}
                    alt="shape"
                    className="lazy-img shapes shape-two"
                />
            </div>
        </div>
    )
}

export default About