import { useState } from "react";
import { url } from "../Data/Url";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SignUpFrom = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isAgree, setIsAgree] = useState(false);
    const [userData, setUserData] = useState({ name: "", email: "", password: "", cpassword: "" });
    let navigate = useNavigate();

    const reSubmission = async () => {
        try {
            const response = await fetch(`${url}/buyer/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData)
            });
            const data = await response.json();
            if (data.success === true) {
                setUserData({ name: "", email: "", password: "", cpassword: "" })
                toast.success("User registered successfully.");
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            }

            else {
                if (data.errors) {
                    data.errors.map((err) => {
                        toast.error(err.msg);
                    })
                }
                else {
                    toast.error(data.error);
                }
            }
        } catch (error) {
            toast.warning("Your network might be slow please try again.");
        }

    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userData.password.length < 8) {
            toast.error("Password must contain at least 08 characters.");
            return false;
        }
        if (userData.cpassword.length < 8) {
            toast.error("Confirm Password must contain at least 08 characters.");
            return false;
        }
        if (userData.cpassword !== userData.password) {
            toast.error("Password and Confirm Password does not match");
            return false;
        }
        try {
            const response = await fetch(`${url}/buyer/create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userData)
            });
            const data = await response.json();
            if (data.success === true) {
                setUserData({ name: "", email: "", password: "", cpassword: "" })
                toast.success("User registered successfully");
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            }

            else {
                if (data.errors) {
                    data.errors.map((err) => {
                        toast.error(err.msg);
                    })
                }
                else {
                    toast.error(data.error);
                }
            }
        } catch (error) {
            reSubmission();
        }
        // handle form submission
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const changeHndler = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }
    const agreementhandler = () => {
        setIsAgree(!isAgree);
    }
    return (
        <form
            action="#"
            className="user-data-form mt-40 lg-mt-30"
            onSubmit={handleSubmit}
        >
            <div className="row">
                <div className="col-12">
                    <div className="input-group-meta mb-25">
                        <label className="text-black">Name</label>
                        <input type="text" placeholder="Jhon" name="name" value={userData.name} onChange={changeHndler} className="custom-input" required />
                    </div>
                </div>
                {/* End .col-12 */}

                <div className="col-12">
                    <div className="input-group-meta mb-30">
                        <label className="text-black">Email</label>
                        <input type="email" className="custom-input" name="email" value={userData.email} onChange={changeHndler} placeholder="example@gmail.com" required />
                    </div>
                </div>
                {/* End .col-12 */}

                <div className="col-12">
                    <div className="input-group-meta mb-25">
                        <label className="text-black">Password</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            onChange={changeHndler}
                            name="password"
                            value={userData.password}
                            className="pass_log_id custom-input"
                            required
                        />
                        <span className="placeholder_icon" onClick={handleTogglePassword}>
                            <span className=" d-flex align-items-center">
                                {showPassword ? (
                                    <>
                                        <i className="fa-regular fa-eye"></i>
                                    </>
                                ) : (
                                    <>
                                        <i className=" fa-regular fa-eye-slash"></i>
                                    </>
                                )}
                            </span>
                        </span>
                        <small>Hint: The password should be at least 08 characters long.</small>
                    </div>
                </div>
                {/* End .col-12 */}

                <div className="col-12">
                    <div className="input-group-meta mb-25">
                        <label className="text-black">Confirm Password</label>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            className="pass_log_id custom-input"
                            value={userData.cpassword}
                            name="cpassword"
                            onChange={changeHndler}
                            required
                        />
                        <span
                            className="placeholder_icon"
                            onClick={handleToggleConfirmPassword}
                        >
                            <span className=" d-flex align-items-center">
                                {showConfirmPassword ? (
                                    <>
                                        <i className="fa-regular fa-eye"></i>
                                    </>
                                ) : (
                                    <>
                                        <i className=" fa-regular fa-eye-slash"></i>
                                    </>
                                )}
                            </span>
                        </span>
                    </div>
                </div>
                {/* End .col-12 */}

                <div className="col-12">
                    <div className="agreement-checkbox d-flex justify-content-between align-items-center">
                        <div>
                            <input type="checkbox" id="agree_to_policy" required onChange={agreementhandler} />
                            <label htmlFor="agree_to_policy">
                                I agree to the Terms and
                                Conditions and Privacy Policy.
                            </label>
                        </div>
                    </div>
                    {/* /.agreement-checkbox */}
                </div>
                {/* End .col-12 */}

                <div className="col-12">
                    <button disabled={!isAgree} className="btn-signup btn-twentyTwo w-100 fw-500 tran3s text-uppercase mt-30">
                        Sign Up
                    </button>
                </div>
                {/* End .col-12 */}
            </div>
        </form>
    );
};

export default SignUpFrom;
