import Aos from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "../components/ScrollTop";
import Header from '../components/Header';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

if (typeof window !== "undefined") {
    require("bootstrap/dist/js/bootstrap");

}


function Layout() {
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        Aos.init({
            duration: 1200,
        });
    }, [])
    return (
        <div className="main-page-wrapper">
            <ScrollToTop />
            <Header />
            <Outlet />
            <p className="mt-auto text-center copy-right-border">Copyright &copy; {`${currentYear}`} Beckmarket inc.</p>
            <ToastContainer />
        </div>
    );
}

export default Layout;
