'use client'
import { useContext, useEffect, useState } from "react";
import { url, imgUrl } from "../Data/Url";
import { toast } from "react-toastify";
import Loader from "../assets/Images/loader.svg"
import { Link } from "react-router-dom";
import ScriptContext from "../store/store";
import CategoryContext from "../store/CategoryContext";

const CategoryProducts = ({ id }) => {
    const scriptCtx = useContext(ScriptContext);
    const categoryCtx = useContext(CategoryContext);
    const category = id && id.replace(/-/g, ' ');
    const [filter, setFilter] = useState(category);
    const [categories, setCategories] = useState([]);
    const [scriptList, setScriptList] = useState([]);
    const [empty, setEmpty] = useState(false);
    const [displayCount, setDisplayCount] = useState(12);

    const loadMoreItems = () => {
        // Increase the displayed count by 12 when "Load More" is clicked
        setDisplayCount(displayCount + 12);
    };
    let filteredItems;
    let finalValue;
    let GraterValue = true;
    filteredItems =
        filter === "*"
            ? scriptList.slice(0, displayCount)
            : scriptList
                .filter((item) => item.category.includes(filter))
                .slice(0, displayCount);
    const filterCategories = filter !== "*" && scriptList.filter((item) => item.category.includes(filter));
    GraterValue = filter !== "*" ? (filterCategories.length > displayCount ? true : false) : scriptList.length > displayCount ? true : false
    // } 
    useEffect(() => {
        setCategories(categoryCtx.data)
        setScriptList(scriptCtx.data)
        setFilter(category)
    }, [id])
    return (
        <div className="portfolio-gallery-nine pt-30">
            <div className="container">
                <ul className="style-none text-center isotop-menu-wrapper g-control-nav-two">
                    <li
                        className={filter === "*" ? "is-checked" : ""}
                    >
                        <Link to={'/products'}>All</Link>
                    </li>
                    {categories.length > 0 && categories.map((category, index) => {
                        return <li key={index + 1}
                            className={filter === category.title ? "is-checked" : ""}
                        // onClick={() => setFilter(category.title)}
                        >
                            <Link to={`/category/${category.title.replace(/ /g, '-')}`}>{category.title}</Link>
                        </li>
                    })}
                </ul>

                <div className="pt-80 pb-20 lg-pt-50 ">
                    <div className="row">
                        {filteredItems.length > 0 ? filteredItems.map((item, index) => (
                            <div className="col-md-6 col-lg-4 col-xl-3" key={index + 1}>
                                <div>
                                    <div className="portfolio-block-seven mb-70 lg-mb-40">
                                        <Link to={`/product/${item.slug ? item.slug : item._id}`}>
                                            <div className="img-meta border">
                                                <img
                                                    // height={250}
                                                    layout="responsive"
                                                    loading="lazy"
                                                    src={`${imgUrl}/${item.thumbnail}`}
                                                    alt="gallary"
                                                    className="w-100 tran5s object-fit-contain"
                                                />
                                            </div>
                                        </Link>

                                        <div className="caption pt-20">
                                            <span className="tag fst-italic position-relative">
                                                {item.category}
                                            </span>
                                            <h6>
                                                {/* <button  className="pj-title" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    {item.name}
                    </button> */}
                                                <Link to={`/product/${item.slug ? item.slug : item._id}`} className="pj-title">
                                                    {item.title}
                                                </Link>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : scriptList.length > 0 && filteredItems.length === 0 ? <h4 className="my-5 text-center">{finalValue.length === 0 ? "No Script Found.." : "Coming Soon.."}</h4> : <img src={Loader} height={60} width={60} className="my-5 mx-auto" />}
                        {GraterValue && scriptList.length > displayCount && (
                            <div className="w-100 pb-80 text-center">
                                <button className="btn-twentyFive tran3s aos-init aos-animate mx-auto btn-sm" onClick={loadMoreItems}>Load More</button>
                            </div>
                        )}
                    </div>

                </div>
            </div>
            {/* <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-fullscreen">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        ...
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}

        </div>
    );
};

export default CategoryProducts;
