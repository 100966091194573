import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Hero = ({ searchValue }) => {
    const [inputValue, setInputValue] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        searchValue(inputValue)
        // Code for handling form submission
    };
    const content = {
        heading: "#1 Marketplace to Sell",
        subheading:
            "Agency work with top rated talented people provides quality scripts.",
        btnText: "Search",
    };
    useEffect(() => {
        if (inputValue.length === 0) {
            searchValue(inputValue)
        }
    }, [inputValue])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
        }, 2000);

        return () => clearInterval(interval);
    }, []);
    return (
        <>
            <h1 className="hero-heading fw-light tx-dark">
                <span className="position-relative">{content.heading}</span>
            </h1>
            <div className="slider-container">
                <h1 className={`slider-item ${currentIndex === 0 ? 'active' : ''} hero-heading fw-light tx-dark`}>
                    Web Apps
                </h1>
                <h1 className={`slider-item ${currentIndex === 1 ? 'active' : ''} hero-heading fw-light tx-dark`}>
                    Mobile Application
                </h1>
                <h1 className={`slider-item ${currentIndex === 2 ? 'active' : ''} hero-heading fw-light tx-dark`}>
                    UI UX
                </h1>
            </div>
            {/* <p className="text-lg mb-75 pt-60 lg-mb-40 lg-pt-40">
        {content.subheading}
      </p> */}
            <div
                className="subscribe-form m-auto"
                data-aos="fade-up"
                data-aos-delay="200"
            >
                <form onSubmit={handleFormSubmit} className="position-relative">
                    <input
                        type="text"
                        placeholder="Enter keyword"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <button type="submit" disabled={inputValue.length === 0} className="tran3s position-absolute fw-500 check-disable">
                        {content.btnText}
                    </button>
                </form>
                <p className="m0 pt-20 fw-500 tx-dark fs-15">
                    Interested in getting new updates?{" "}
                    <Link to="/signup" className="text-decoration-underline">
                        Sign up
                    </Link>
                </p>
            </div>
        </>
    );
};

export default Hero;
