import Layout from "../Layout/Layout"
import Home from "../pages/Home"
import About from "../pages/About"
import Product from "../pages/Product"
import Contact from "../pages/ContactUs"
import ProductDetail from "../pages/ProductDetail"
import CategoryWiseProducts from "../pages/Category"
import SignUp from "../pages/SignUp"

const ThemeRoutes = [
    {
        path: "/",
        element: <Layout />,
        children: [
            { path: "/", exact: true, element: <Home /> },
            { path: "/about-us", exact: true, element: <About /> },
            { path: "/products", exact: true, element: <Product /> },
            { path: "/contact", exact: true, element: <Contact /> },
            { path: "/product/:id", exact: true, element: <ProductDetail /> },
            { path: "/category/:id", exact: true, element: <CategoryWiseProducts /> },
        ]
    },
    {
        path: "/signup",
        exact: true,
        element: <SignUp />
    }
]

export default ThemeRoutes;