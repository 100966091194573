import { useEffect, useState } from "react";
import MainMenu from "./MainMenu";
import Logo from "../assets/Images/logo/b_logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 10) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", changeBackground);
        return () => {
            window.removeEventListener("scroll", changeBackground);
        };
    }, []);

    return (
        <header
            className={`theme-main-menu sticky-menu theme-menu-two ${navbar ? "fixed" : ""
                }`}
        >
            <div className="inner-content position-relative">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="logo order-lg-0">
                        <Link to="/" className="d-block">
                            <img
                                src={Logo}
                                alt="logo"
                                width={200}
                                height={40}
                            />
                        </Link>
                    </div>
                    <div className="right-widget d-flex align-items-center ms-auto ms-lg-0 order-lg-3">
                        {/* <Link
              href="/login"
              className="login-btn-one fs-17 fw-500 tran3s me-3"
            >
              Login
            </Link> */}
                        <Link
                            to="/contact"
                            className="contact-btn-three fs-17 fw-500 tran3s d-none d-lg-block"
                        >
                            Contact us
                        </Link>
                    </div>
                    {/* /.right-widget */}
                    <MainMenu />
                </div>
            </div>
            {/* /.inner-content */}
        </header>
    );
};

export default Header;
