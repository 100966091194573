import React, { useEffect } from 'react'
import Products from "../components/Products"
import PortfolioTopTitle from "../components/PortfolioTopTitle"
import CallToAction from "../components/CallToAction"
import Partner from "../components/Partners"
import shape_38 from "../assets/Images/shape/shape_38.svg"
import shape_39 from "../assets/Images/shape/shape_39.svg"
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Product = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname])
    return (
        <div>
            <Helmet>
                <title>Products</title>
            </Helmet>
            <PortfolioTopTitle />
            <div className="xs-mt-10 mtt-5">
                <Products />
            </div>
            <CallToAction />
            <div className="partner-section-one position-relative my-5-theme lg-mt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-11 m-auto">
                            <div className="row align-items-center">
                                <Partner />
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.container */}

                <img
                    src={shape_38}
                    alt="shape"
                    className="lazy-img shapes shape-one"
                />
                <img
                    src={shape_39}
                    alt="shape"
                    className="lazy-img shapes shape-two"
                />
            </div>
        </div>
    )
}

export default Product