import microbeck from '../assets/Images/blog/microbeck-without-tag-black.png';
import tecbeck from '../assets/Images/blog/tecbeck_logo.png';
import techstreams from '../assets/Images/blog/techstreams_logo.png';
import altratech from '../assets/Images/blog/altratech_logo.png';
import React from 'react';
const Partners = () => {
    const partnersData = [
        {
            imgSrc: microbeck,
            imgAlt: "logo",
            divClass: "img-box bx-a",
            link: "#",
            colClasses: "col-xl-3 col-lg-3 col-md-4 col-6",
            dataAos: "fade",
            dataAosDelay: "100",
        },
        {
            imgSrc: tecbeck,
            imgAlt: "logo",
            divClass: "img-box bx-b",
            link: "#",
            colClasses: "col-xl-3 col-lg-3 col-md-4 col-6",
            dataAos: "fade",
            dataAosDelay: "200",
        },
        {
            imgSrc: techstreams,
            imgAlt: "logo",
            divClass: "img-box bx-c",
            link: "#",
            colClasses: "col-xl-3 col-lg-3 col-md-4 col-6",
            dataAos: "fade",
            dataAosDelay: "300",
        },
        {
            imgSrc: altratech,
            imgAlt: "logo",
            divClass: "img-box bx-d",
            link: "#",
            colClasses: "col-xl-3 col-lg-3 col-md-4 col-6",
            dataAos: "fade",
            dataAosDelay: "300",
        }
    ];

    return (
        <React.Fragment>
            {partnersData.map((partner, index) => (
                <div
                    key={index}
                    className={partner.colClasses}
                // data-aos={partner.dataAos}
                // data-aos-delay={partner.dataAosDelay}
                >
                    <div className={`img-box ${partner.className}`}>
                        <a href="#" className="p-3">
                            <img src={partner.imgSrc} alt="logo" className="lazy-img" />
                        </a>
                    </div>
                    {/* /.img-box */}
                </div>
            ))}
        </React.Fragment>
    );
};

export default Partners;
