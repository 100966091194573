const Map = () => {
    return (
        <div className="map-area-one p-30 mt-120 lg-mt-80 wow fadeInUp">
            <div className="box-layout">
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe
                            className="gmap_iframe"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13617.297071556934!2d73.1248289!3d31.4327387!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392269d88214567d%3A0x172dc75b45924b9e!2sTecbeck%20Pvt%20Ltd.!5e0!3m2!1sen!2s!4v1702453362144!5m2!1sen!2s" width="600" height="450"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Map;
