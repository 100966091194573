const accordionItems = [
    {
        id: 1,
        title: "Web App",
        content:
            "Offers advanced web applications with unique design and excellent functionality to help you boost your digital presence.",
    },
    {
        id: 2,
        title: "Mobile App",
        content:
            "Offers accessible user interfaces and powerful features, you can fully realize the potential of mobile technology.",
    },
    {
        id: 3,
        title: "UI/UX",
        content:
            "Completely change the look and feel of your application while providing your users with an unforgettable experience.",
    },
];

const Faq = () => {
    return (
        <div className="accordion accordion-style-two md-mt-60" id="accordionOne">
            {accordionItems.map((item) => (
                <div className="accordion-item" key={item.id}>
                    <div className="accordion-header" id={`heading${item.id}`}>
                        <button
                            className={`accordion-button ${item.id === 1 ? "" : "collapsed"}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${item.id}`}
                            aria-expanded={item.id === 1 ? "true" : "false"}
                            aria-controls={`collapse${item.id}`}
                        >
                            {item.title}
                        </button>
                    </div>
                    <div
                        id={`collapse${item.id}`}
                        className={`accordion-collapse collapse${item.id === 1 ? " show" : ""
                            }`}
                        aria-labelledby={`heading${item.id}`}
                        data-bs-parent="#accordionOne"
                    >
                        <div className="accordion-body">
                            <p>{item.content}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Faq;
