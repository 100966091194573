import shape_172 from "../assets/Images/shape/shape_172.svg"
import shape_175 from "../assets/Images/shape/shape_175.svg"

const PortfolioDetailsTitle = ({ portfolio }) => {
    return (
        <div className="fancy-feature-fiftyOne position-relative mt-100 md-mt-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8" data-aos="fade-right">
                        <div className="title-style-five xs-mb-10 lg-mb-40">
                            {/* <div className="sc-title-two fst-italic position-relative">
                  {portfolio?.category} 
                </div> */}
                            <h2 className="fw-500 tx-dark mt-20 mb-50">{portfolio?.detailName}</h2>
                        </div>
                    </div>
                </div>
            </div>
            {/* /.container */}
            <img
                src={shape_172}
                alt="shap"
                className="lazy-img shapes shape-two"
            />
            <img
                src={shape_175}
                alt="shap"
                className="lazy-img shapes shape-three"
            />
        </div>
    );
};

export default PortfolioDetailsTitle;
