const SocialShare = ({ liveLink, toggleTarget, target, category, setSubject }) => {
    const socialMedia = [
        {
            name: "Facebook",
            icon: "fab fa-facebook-f",
            url: "#",
        },
        {
            name: "Twitter",
            icon: "fab fa-twitter",
            url: "#",
        },
        {
            name: "LinkedIn",
            icon: "fab fa-linkedin-in",
            url: "#",
        },
    ];
    return (
        <ul className="style-none social-icon gap-2 d-flex pt-10">
            <li
                className="d-flex align-items-center justify-content-center"
            >
                <a className="btn-twentyTwo tran3s aos-init aos-animate" onClick={(() => setSubject())} data-aos="fade-left" data-bs-toggle={toggleTarget} data-bs-target={target} href="#">Buy Now</a>
            </li>
            {category === "Web App" && <li
                className="d-flex align-items-center justify-content-center"
            >
                <a className="btn-twentyFive tran3s aos-init aos-animate" data-aos="fade-left" target="_blank" href={liveLink && liveLink}>Live Demo</a>
            </li>}
        </ul>
    );
};

export default SocialShare;
